<template>
  <div>
    <app-breadcrumb
      action="Top up manual"
      link="/mecarePay/postman"
    />
    <b-card-code
      no-body
      title="Data Withdraw Request"
    >
      <b-table
        ref="refMitraListTable"
        :items="itemsWithDraw"
        responsive
        :sort-by.sync="sortBy"
        :sort-desc.sync="isSortDirDesc"
        :fields="tabColumns"
        class="position-relative"
      >
        <template #cell(waktu)="data">
          {{ data.item.data.waktu.toDate().toLocaleDateString() }}
        </template>
        <template #cell(norek_mitra)="data">
          <b-media vertical-align="center">
            <template #aside>
              {{ data.item.data.norek_mitra }}
            </template>
          </b-media>
          <small class="text-muted">{{ data.item.data.atas_nama }}</small>
        </template>
        <template #cell(atas_nama)="data">
          <b-media vertical-align="center">
            <template #aside>
              {{ data.item.data.nama_mitra }}
            </template>
          </b-media>
          <small class="text-muted">{{ data.item.data.no_hp }}</small>
        </template>
        <template #cell(jumlah)="data">
          {{ data.item.data.jumlah }}
        </template>
        <template #cell(status)="data">
          {{ kodeStatus(data.item.data.status) }}
        </template>
        <template #cell(opsi)="data">
          <b-dropdown
            variant="link"
            toggle-class="p-0"
            no-caret
          >
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>
            <b-dropdown-item
              @click="withDrawACC(data.item.id, data.item.data.no_hp)"
            >
              <feather-icon
                icon="CheckCircleIcon"
              />
              <span class="align-middle ml-50">Konfirmasi</span>
            </b-dropdown-item>
            <b-dropdown-divider />
            <b-dropdown-item
              @click="cancelWithdraw(data.item.id, data.item.data.no_hp, data.item.data.jumlah)"
            >
              <feather-icon
                class="text-danger"
                icon="InfoIcon"
              />
              <span class="align-middle ml-50 text-danger">Cancel Withdraw</span>
            </b-dropdown-item>
            <b-dropdown-item
              @click="hapusTransaksi(data.item.id)"
            >
              <feather-icon
                class="text-danger"
                icon="CopyIcon"
              />
              <span class="align-middle ml-50 text-danger">Dihapus</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>
      </b-table>
    </b-card-code>
  </div>
</template>

<script>
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import AppBreadcrumb from '@core/layouts/components/AppBreadcrumb.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import axios from 'axios'
import {
  BTable,
  BDropdown,
  BDropdownItem,
  BDropdownDivider,
  BMedia,
} from 'bootstrap-vue'
import { db } from '@/firebase'

export default {
  /* eslint-disable vue/no-unused-components */
  components: {
    BTable,
    BCardCode,
    BDropdown,
    BDropdownItem,
    BDropdownDivider,
    BMedia,
    AppBreadcrumb,

    axios,
  },
  data() {
    return {
      tabColumns: [
        { key: 'waktu', label: 'waktu', sortable: true },
        { key: 'norek_mitra', label: 'Rekening', sortable: false },
        { key: 'atas_nama', label: 'Mitra', sortable: false },
        { key: 'Jumlah', label: 'Jumlah', sortable: false },
        { key: 'status', label: 'Status', sortable: false },
        { key: 'opsi', label: '' },
      ],
      itemsWithDraw: [],
      sortBy: 'waktu',
      isSortDirDesc: false,
      perPage: 5,
    }
  },
  created() {
    this.getWithdraw()
  },
  methods: {
    /* eslint-disable no-unused-vars */
    getWithdraw() {
      const limit = this.perPage <= 2 ? 2 : this.perPage
      this.loadMorebtn = true

      db.collection('withdrawRequest').orderBy('waktu', 'desc').get().then(snapshot => {
        const arr = []
        snapshot.docs.forEach(doc => {
          arr.push({
            id: doc.id,
            data: doc.data(),
          })
        })
        this.itemsWithDraw = [...new Set(arr)]
      })
    },
    kodeStatus(kdStatus) {
      const status = {
        false: 'Belum Dikonfirmasi',
        true: 'Dikonfirmasi',
      }

      return status[kdStatus]
    },
    cancelWithdraw(id, noHp, jumlahSaldo) {
      this.$swal({
        title: 'Cancel withdraw ini ?',
        text: 'Konfirmasi jika anda ingin mengcancel data withdraw',
        showCancelButton: true,
        confirmButtonText: 'Yakin',
        cancelButtonText: 'Batalkan',
        customClass: {
          confirmButton: 'btn btn-danger',
          cancelButton: 'text-danger btn btn-outline-danger ml-1',
        },
      })
        .then(result => {
          if (result.value) {
            const options = {
              url: 'https://us-central1-mecare-life.cloudfunctions.net/manualTopUp',
              method: 'POST',
              data: {
                no_hp: noHp,
                jumlah: jumlahSaldo,
                aplikasi: '',
              },
            }
            this.$axios(options).then(() => {
              db.collection('withdrawRequest').doc(id).delete().then(() => {
                this.getWithdraw()
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Cancel Withdraw berhasil',
                    icon: 'EditIcon',
                    variant: 'success',
                  },
                })
              })
            }).catch(() => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Cancel Withdraw tidak berhasil',
                  icon: 'XIcon',
                  variant: 'warning',
                },
              })
            })
          }
        })
    },
    hapusTransaksi(id) {
      this.$swal({
        title: 'Hapus data ini?',
        text: 'Konfirmasi jika anda ingin menghapus data withdraw',
        showCancelButton: true,
        confirmButtonText: 'Yakin',
        cancelButtonText: 'Batalkan',
        customClass: {
          confirmButton: 'btn btn-danger',
          cancelButton: 'text-danger btn btn-outline-danger ml-1',
        }.then(result => {
          if (result.value) {
            db.collection('withdrawRequest').doc(id).delete().then(() => {
              this.getWithdraw()
              this.$toast({
                components: ToastificationContent,
                props: {
                  title: 'Berhasil Menghapus Data Withdraw',
                  icon: 'TrashIcon',
                  variant: 'success',
                },
              })
            })
              .catch(error => {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: error.value,
                    icon: 'EditIcon',
                    variant: 'warning',
                  },
                })
              })
          }
        }),
      })
    },
    withDrawACC(id, noHp) {
      this.$swal({
        title: 'Konfirmasi Withdraw?',
        text: 'Konfirmasikan jika anda ingin mengaktifkan mitra',
        showCancelButton: true,
        confirmButtonText: 'Yakin',
        cancelButtonText: 'Batalkan',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'text-danger btn btn-outline-danger ml-1',
        },
        buttonStyling: false,
      }).then(result => {
        if (result.value) {
          db.collection('withdrawRequest').doc(id).update({
            status: true,
          })
            .then(() => {
              db.collection('mitra').doc(noHp).collection('payment_log').where('ref_id', '==', id)
                .get()
                .then(snapshot => {
                  const withdrawData = snapshot.docs[0]

                  db.collection('mitra').doc(noHp).collection('payment_log').doc(withdrawData.id)
                    .update({
                      status: true,
                    })
                    .then(() => {
                      this.getWithdraw()
                      this.$toast({
                        component: ToastificationContent,
                        props: {
                          title: 'Berhasil dikonfirmasi',
                          icon: 'success',
                          variant: 'success',
                        },
                      })
                    })
                    .catch(error => {
                      this.$toast({
                        component: ToastificationContent,
                        props: {
                          title: error.value,
                          icon: 'EditIcon',
                          variant: 'warning',
                        },
                      })
                    })
                })
            })
        }
      })
    },
  },
}
</script>

<style>

</style>
